"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Error format
 *
 * {@link https://postgrest.org/en/stable/api.html?highlight=options#errors-and-http-status-codes}
 */
class PostgrestError extends Error {
  constructor(context) {
    super(context.message);
    this.name = 'PostgrestError';
    this.details = context.details;
    this.hint = context.hint;
    this.code = context.code;
  }
}
exports.default = PostgrestError;
