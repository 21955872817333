import { InjectionToken } from '@angular/core';

export type WebsiteDefaultValuesConfig = {
  publisherLogo: string;
  authorLogo: string;
  metaTitle: string;
  siteName: string;
  categorizedWith: string;
};

export const WEBSITE_DEFAULT_VALUES_CONFIG =
  new InjectionToken<WebsiteDefaultValuesConfig>('WebsiteDefaultValuesConfig');
