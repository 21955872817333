import { CustomLogger, CustomLoggerFE } from '@himingle/shared';
import { environment } from '../environments/environment';

export const customLogger = {
  provide: CustomLogger,
  useFactory: () => {
    return new CustomLoggerFE(environment.production);
  },
  deps: [],
};
