import { WebsiteDefaultValuesConfig } from './website-default';

export const websiteDefaultValues: WebsiteDefaultValuesConfig = {
  publisherLogo: 'assets/images/favicon-256x256.png',
  authorLogo: 'assets/images/favicon-96x96.png',
  metaTitle: 'DigestBrief.com',
  categorizedWith: 'Productivity',
  siteName:
    'DigestBrief. Stay Informed, Not Overwhelmed: Your All-in-One Digest Delivered!',
};
