  <!-- Page wrapper -->
<!--  <div-->
<!--    class="font-inter bg-indigo-100 tracking-tight text-gray-800 antialiased"-->
<!--  >-->
<!--    <header web-header class="z-30 mt-2 w-full md:mt-5"></header>-->
    <!-- Page content -->
<!--    <main class=" grow">-->
      <router-outlet></router-outlet>
<!--    </main>-->
<!--    <footer web-footer></footer>-->
<!--  </div>-->

<!--  </body>-->

