import { __assign, __extends } from 'tslib';
import { ApolloCache } from 'apollo-cache';
import { isTest, getQueryDefinition, assign, getDefaultValues, isEqual, getMainDefinition, getFragmentDefinitions, createFragmentMap, shouldInclude, isField, resultKeyNameFromField, isInlineFragment, mergeDeepArray, argumentsObjectFromField, getDirectiveInfoFromField, maybeDeepFreeze, isIdValue, getStoreKeyName, toIdValue, isJsonValue, canUseWeakMap, getOperationDefinition, isProduction, storeKeyNameFromField, addTypenameToDocument } from 'apollo-utilities';
import { wrap, KeyTrie } from 'optimism';
import { invariant, InvariantError } from 'ts-invariant';
var haveWarned = false;
function shouldWarn() {
  var answer = !haveWarned;
  if (!isTest()) {
    haveWarned = true;
  }
  return answer;
}
var HeuristicFragmentMatcher = function () {
  function HeuristicFragmentMatcher() {}
  HeuristicFragmentMatcher.prototype.ensureReady = function () {
    return Promise.resolve();
  };
  HeuristicFragmentMatcher.prototype.canBypassInit = function () {
    return true;
  };
  HeuristicFragmentMatcher.prototype.match = function (idValue, typeCondition, context) {
    var obj = context.store.get(idValue.id);
    var isRootQuery = idValue.id === 'ROOT_QUERY';
    if (!obj) {
      return isRootQuery;
    }
    var _a = obj.__typename,
      __typename = _a === void 0 ? isRootQuery && 'Query' : _a;
    if (!__typename) {
      if (shouldWarn()) {
        process.env.NODE_ENV === "production" || invariant.warn("You're using fragments in your queries, but either don't have the addTypename:\n  true option set in Apollo Client, or you are trying to write a fragment to the store without the __typename.\n   Please turn on the addTypename option and include __typename when writing fragments so that Apollo Client\n   can accurately match fragments.");
        process.env.NODE_ENV === "production" || invariant.warn('Could not find __typename on Fragment ', typeCondition, obj);
        process.env.NODE_ENV === "production" || invariant.warn("DEPRECATION WARNING: using fragments without __typename is unsupported behavior " + "and will be removed in future versions of Apollo client. You should fix this and set addTypename to true now.");
      }
      return 'heuristic';
    }
    if (__typename === typeCondition) {
      return true;
    }
    if (shouldWarn()) {
      process.env.NODE_ENV === "production" || invariant.error('You are using the simple (heuristic) fragment matcher, but your ' + 'queries contain union or interface types. Apollo Client will not be ' + 'able to accurately map fragments. To make this error go away, use ' + 'the `IntrospectionFragmentMatcher` as described in the docs: ' + 'https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher');
    }
    return 'heuristic';
  };
  return HeuristicFragmentMatcher;
}();
var IntrospectionFragmentMatcher = function () {
  function IntrospectionFragmentMatcher(options) {
    if (options && options.introspectionQueryResultData) {
      this.possibleTypesMap = this.parseIntrospectionResult(options.introspectionQueryResultData);
      this.isReady = true;
    } else {
      this.isReady = false;
    }
    this.match = this.match.bind(this);
  }
  IntrospectionFragmentMatcher.prototype.match = function (idValue, typeCondition, context) {
    process.env.NODE_ENV === "production" ? invariant(this.isReady, 1) : invariant(this.isReady, 'FragmentMatcher.match() was called before FragmentMatcher.init()');
    var obj = context.store.get(idValue.id);
    var isRootQuery = idValue.id === 'ROOT_QUERY';
    if (!obj) {
      return isRootQuery;
    }
    var _a = obj.__typename,
      __typename = _a === void 0 ? isRootQuery && 'Query' : _a;
    process.env.NODE_ENV === "production" ? invariant(__typename, 2) : invariant(__typename, "Cannot match fragment because __typename property is missing: " + JSON.stringify(obj));
    if (__typename === typeCondition) {
      return true;
    }
    var implementingTypes = this.possibleTypesMap[typeCondition];
    if (__typename && implementingTypes && implementingTypes.indexOf(__typename) > -1) {
      return true;
    }
    return false;
  };
  IntrospectionFragmentMatcher.prototype.parseIntrospectionResult = function (introspectionResultData) {
    var typeMap = {};
    introspectionResultData.__schema.types.forEach(function (type) {
      if (type.kind === 'UNION' || type.kind === 'INTERFACE') {
        typeMap[type.name] = type.possibleTypes.map(function (implementingType) {
          return implementingType.name;
        });
      }
    });
    return typeMap;
  };
  return IntrospectionFragmentMatcher;
}();
var hasOwn = Object.prototype.hasOwnProperty;
var DepTrackingCache = function () {
  function DepTrackingCache(data) {
    var _this = this;
    if (data === void 0) {
      data = Object.create(null);
    }
    this.data = data;
    this.depend = wrap(function (dataId) {
      return _this.data[dataId];
    }, {
      disposable: true,
      makeCacheKey: function (dataId) {
        return dataId;
      }
    });
  }
  DepTrackingCache.prototype.toObject = function () {
    return this.data;
  };
  DepTrackingCache.prototype.get = function (dataId) {
    this.depend(dataId);
    return this.data[dataId];
  };
  DepTrackingCache.prototype.set = function (dataId, value) {
    var oldValue = this.data[dataId];
    if (value !== oldValue) {
      this.data[dataId] = value;
      this.depend.dirty(dataId);
    }
  };
  DepTrackingCache.prototype.delete = function (dataId) {
    if (hasOwn.call(this.data, dataId)) {
      delete this.data[dataId];
      this.depend.dirty(dataId);
    }
  };
  DepTrackingCache.prototype.clear = function () {
    this.replace(null);
  };
  DepTrackingCache.prototype.replace = function (newData) {
    var _this = this;
    if (newData) {
      Object.keys(newData).forEach(function (dataId) {
        _this.set(dataId, newData[dataId]);
      });
      Object.keys(this.data).forEach(function (dataId) {
        if (!hasOwn.call(newData, dataId)) {
          _this.delete(dataId);
        }
      });
    } else {
      Object.keys(this.data).forEach(function (dataId) {
        _this.delete(dataId);
      });
    }
  };
  return DepTrackingCache;
}();
function defaultNormalizedCacheFactory(seed) {
  return new DepTrackingCache(seed);
}
var StoreReader = function () {
  function StoreReader(_a) {
    var _this = this;
    var _b = _a === void 0 ? {} : _a,
      _c = _b.cacheKeyRoot,
      cacheKeyRoot = _c === void 0 ? new KeyTrie(canUseWeakMap) : _c,
      _d = _b.freezeResults,
      freezeResults = _d === void 0 ? false : _d;
    var _e = this,
      executeStoreQuery = _e.executeStoreQuery,
      executeSelectionSet = _e.executeSelectionSet,
      executeSubSelectedArray = _e.executeSubSelectedArray;
    this.freezeResults = freezeResults;
    this.executeStoreQuery = wrap(function (options) {
      return executeStoreQuery.call(_this, options);
    }, {
      makeCacheKey: function (_a) {
        var query = _a.query,
          rootValue = _a.rootValue,
          contextValue = _a.contextValue,
          variableValues = _a.variableValues,
          fragmentMatcher = _a.fragmentMatcher;
        if (contextValue.store instanceof DepTrackingCache) {
          return cacheKeyRoot.lookup(contextValue.store, query, fragmentMatcher, JSON.stringify(variableValues), rootValue.id);
        }
      }
    });
    this.executeSelectionSet = wrap(function (options) {
      return executeSelectionSet.call(_this, options);
    }, {
      makeCacheKey: function (_a) {
        var selectionSet = _a.selectionSet,
          rootValue = _a.rootValue,
          execContext = _a.execContext;
        if (execContext.contextValue.store instanceof DepTrackingCache) {
          return cacheKeyRoot.lookup(execContext.contextValue.store, selectionSet, execContext.fragmentMatcher, JSON.stringify(execContext.variableValues), rootValue.id);
        }
      }
    });
    this.executeSubSelectedArray = wrap(function (options) {
      return executeSubSelectedArray.call(_this, options);
    }, {
      makeCacheKey: function (_a) {
        var field = _a.field,
          array = _a.array,
          execContext = _a.execContext;
        if (execContext.contextValue.store instanceof DepTrackingCache) {
          return cacheKeyRoot.lookup(execContext.contextValue.store, field, array, JSON.stringify(execContext.variableValues));
        }
      }
    });
  }
  StoreReader.prototype.readQueryFromStore = function (options) {
    return this.diffQueryAgainstStore(__assign(__assign({}, options), {
      returnPartialData: false
    })).result;
  };
  StoreReader.prototype.diffQueryAgainstStore = function (_a) {
    var store = _a.store,
      query = _a.query,
      variables = _a.variables,
      previousResult = _a.previousResult,
      _b = _a.returnPartialData,
      returnPartialData = _b === void 0 ? true : _b,
      _c = _a.rootId,
      rootId = _c === void 0 ? 'ROOT_QUERY' : _c,
      fragmentMatcherFunction = _a.fragmentMatcherFunction,
      config = _a.config;
    var queryDefinition = getQueryDefinition(query);
    variables = assign({}, getDefaultValues(queryDefinition), variables);
    var context = {
      store: store,
      dataIdFromObject: config && config.dataIdFromObject,
      cacheRedirects: config && config.cacheRedirects || {}
    };
    var execResult = this.executeStoreQuery({
      query: query,
      rootValue: {
        type: 'id',
        id: rootId,
        generated: true,
        typename: 'Query'
      },
      contextValue: context,
      variableValues: variables,
      fragmentMatcher: fragmentMatcherFunction
    });
    var hasMissingFields = execResult.missing && execResult.missing.length > 0;
    if (hasMissingFields && !returnPartialData) {
      execResult.missing.forEach(function (info) {
        if (info.tolerable) return;
        throw process.env.NODE_ENV === "production" ? new InvariantError(8) : new InvariantError("Can't find field " + info.fieldName + " on object " + JSON.stringify(info.object, null, 2) + ".");
      });
    }
    if (previousResult) {
      if (isEqual(previousResult, execResult.result)) {
        execResult.result = previousResult;
      }
    }
    return {
      result: execResult.result,
      complete: !hasMissingFields
    };
  };
  StoreReader.prototype.executeStoreQuery = function (_a) {
    var query = _a.query,
      rootValue = _a.rootValue,
      contextValue = _a.contextValue,
      variableValues = _a.variableValues,
      _b = _a.fragmentMatcher,
      fragmentMatcher = _b === void 0 ? defaultFragmentMatcher : _b;
    var mainDefinition = getMainDefinition(query);
    var fragments = getFragmentDefinitions(query);
    var fragmentMap = createFragmentMap(fragments);
    var execContext = {
      query: query,
      fragmentMap: fragmentMap,
      contextValue: contextValue,
      variableValues: variableValues,
      fragmentMatcher: fragmentMatcher
    };
    return this.executeSelectionSet({
      selectionSet: mainDefinition.selectionSet,
      rootValue: rootValue,
      execContext: execContext
    });
  };
  StoreReader.prototype.executeSelectionSet = function (_a) {
    var _this = this;
    var selectionSet = _a.selectionSet,
      rootValue = _a.rootValue,
      execContext = _a.execContext;
    var fragmentMap = execContext.fragmentMap,
      contextValue = execContext.contextValue,
      variables = execContext.variableValues;
    var finalResult = {
      result: null
    };
    var objectsToMerge = [];
    var object = contextValue.store.get(rootValue.id);
    var typename = object && object.__typename || rootValue.id === 'ROOT_QUERY' && 'Query' || void 0;
    function handleMissing(result) {
      var _a;
      if (result.missing) {
        finalResult.missing = finalResult.missing || [];
        (_a = finalResult.missing).push.apply(_a, result.missing);
      }
      return result.result;
    }
    selectionSet.selections.forEach(function (selection) {
      var _a;
      if (!shouldInclude(selection, variables)) {
        return;
      }
      if (isField(selection)) {
        var fieldResult = handleMissing(_this.executeField(object, typename, selection, execContext));
        if (typeof fieldResult !== 'undefined') {
          objectsToMerge.push((_a = {}, _a[resultKeyNameFromField(selection)] = fieldResult, _a));
        }
      } else {
        var fragment = void 0;
        if (isInlineFragment(selection)) {
          fragment = selection;
        } else {
          fragment = fragmentMap[selection.name.value];
          if (!fragment) {
            throw process.env.NODE_ENV === "production" ? new InvariantError(9) : new InvariantError("No fragment named " + selection.name.value);
          }
        }
        var typeCondition = fragment.typeCondition && fragment.typeCondition.name.value;
        var match = !typeCondition || execContext.fragmentMatcher(rootValue, typeCondition, contextValue);
        if (match) {
          var fragmentExecResult = _this.executeSelectionSet({
            selectionSet: fragment.selectionSet,
            rootValue: rootValue,
            execContext: execContext
          });
          if (match === 'heuristic' && fragmentExecResult.missing) {
            fragmentExecResult = __assign(__assign({}, fragmentExecResult), {
              missing: fragmentExecResult.missing.map(function (info) {
                return __assign(__assign({}, info), {
                  tolerable: true
                });
              })
            });
          }
          objectsToMerge.push(handleMissing(fragmentExecResult));
        }
      }
    });
    finalResult.result = mergeDeepArray(objectsToMerge);
    if (this.freezeResults && process.env.NODE_ENV !== 'production') {
      Object.freeze(finalResult.result);
    }
    return finalResult;
  };
  StoreReader.prototype.executeField = function (object, typename, field, execContext) {
    var variables = execContext.variableValues,
      contextValue = execContext.contextValue;
    var fieldName = field.name.value;
    var args = argumentsObjectFromField(field, variables);
    var info = {
      resultKey: resultKeyNameFromField(field),
      directives: getDirectiveInfoFromField(field, variables)
    };
    var readStoreResult = readStoreResolver(object, typename, fieldName, args, contextValue, info);
    if (Array.isArray(readStoreResult.result)) {
      return this.combineExecResults(readStoreResult, this.executeSubSelectedArray({
        field: field,
        array: readStoreResult.result,
        execContext: execContext
      }));
    }
    if (!field.selectionSet) {
      assertSelectionSetForIdValue(field, readStoreResult.result);
      if (this.freezeResults && process.env.NODE_ENV !== 'production') {
        maybeDeepFreeze(readStoreResult);
      }
      return readStoreResult;
    }
    if (readStoreResult.result == null) {
      return readStoreResult;
    }
    return this.combineExecResults(readStoreResult, this.executeSelectionSet({
      selectionSet: field.selectionSet,
      rootValue: readStoreResult.result,
      execContext: execContext
    }));
  };
  StoreReader.prototype.combineExecResults = function () {
    var execResults = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      execResults[_i] = arguments[_i];
    }
    var missing;
    execResults.forEach(function (execResult) {
      if (execResult.missing) {
        missing = missing || [];
        missing.push.apply(missing, execResult.missing);
      }
    });
    return {
      result: execResults.pop().result,
      missing: missing
    };
  };
  StoreReader.prototype.executeSubSelectedArray = function (_a) {
    var _this = this;
    var field = _a.field,
      array = _a.array,
      execContext = _a.execContext;
    var missing;
    function handleMissing(childResult) {
      if (childResult.missing) {
        missing = missing || [];
        missing.push.apply(missing, childResult.missing);
      }
      return childResult.result;
    }
    array = array.map(function (item) {
      if (item === null) {
        return null;
      }
      if (Array.isArray(item)) {
        return handleMissing(_this.executeSubSelectedArray({
          field: field,
          array: item,
          execContext: execContext
        }));
      }
      if (field.selectionSet) {
        return handleMissing(_this.executeSelectionSet({
          selectionSet: field.selectionSet,
          rootValue: item,
          execContext: execContext
        }));
      }
      assertSelectionSetForIdValue(field, item);
      return item;
    });
    if (this.freezeResults && process.env.NODE_ENV !== 'production') {
      Object.freeze(array);
    }
    return {
      result: array,
      missing: missing
    };
  };
  return StoreReader;
}();
function assertSelectionSetForIdValue(field, value) {
  if (!field.selectionSet && isIdValue(value)) {
    throw process.env.NODE_ENV === "production" ? new InvariantError(10) : new InvariantError("Missing selection set for object of type " + value.typename + " returned for query field " + field.name.value);
  }
}
function defaultFragmentMatcher() {
  return true;
}
function assertIdValue(idValue) {
  process.env.NODE_ENV === "production" ? invariant(isIdValue(idValue), 11) : invariant(isIdValue(idValue), "Encountered a sub-selection on the query, but the store doesn't have an object reference. This should never happen during normal use unless you have custom code that is directly manipulating the store; please file an issue.");
}
function readStoreResolver(object, typename, fieldName, args, context, _a) {
  var resultKey = _a.resultKey,
    directives = _a.directives;
  var storeKeyName = fieldName;
  if (args || directives) {
    storeKeyName = getStoreKeyName(storeKeyName, args, directives);
  }
  var fieldValue = void 0;
  if (object) {
    fieldValue = object[storeKeyName];
    if (typeof fieldValue === 'undefined' && context.cacheRedirects && typeof typename === 'string') {
      var type = context.cacheRedirects[typename];
      if (type) {
        var resolver = type[fieldName];
        if (resolver) {
          fieldValue = resolver(object, args, {
            getCacheKey: function (storeObj) {
              var id = context.dataIdFromObject(storeObj);
              return id && toIdValue({
                id: id,
                typename: storeObj.__typename
              });
            }
          });
        }
      }
    }
  }
  if (typeof fieldValue === 'undefined') {
    return {
      result: fieldValue,
      missing: [{
        object: object,
        fieldName: storeKeyName,
        tolerable: false
      }]
    };
  }
  if (isJsonValue(fieldValue)) {
    fieldValue = fieldValue.json;
  }
  return {
    result: fieldValue
  };
}
var ObjectCache = function () {
  function ObjectCache(data) {
    if (data === void 0) {
      data = Object.create(null);
    }
    this.data = data;
  }
  ObjectCache.prototype.toObject = function () {
    return this.data;
  };
  ObjectCache.prototype.get = function (dataId) {
    return this.data[dataId];
  };
  ObjectCache.prototype.set = function (dataId, value) {
    this.data[dataId] = value;
  };
  ObjectCache.prototype.delete = function (dataId) {
    this.data[dataId] = void 0;
  };
  ObjectCache.prototype.clear = function () {
    this.data = Object.create(null);
  };
  ObjectCache.prototype.replace = function (newData) {
    this.data = newData || Object.create(null);
  };
  return ObjectCache;
}();
function defaultNormalizedCacheFactory$1(seed) {
  return new ObjectCache(seed);
}
var WriteError = function (_super) {
  __extends(WriteError, _super);
  function WriteError() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.type = 'WriteError';
    return _this;
  }
  return WriteError;
}(Error);
function enhanceErrorWithDocument(error, document) {
  var enhancedError = new WriteError("Error writing result to store for query:\n " + JSON.stringify(document));
  enhancedError.message += '\n' + error.message;
  enhancedError.stack = error.stack;
  return enhancedError;
}
var StoreWriter = function () {
  function StoreWriter() {}
  StoreWriter.prototype.writeQueryToStore = function (_a) {
    var query = _a.query,
      result = _a.result,
      _b = _a.store,
      store = _b === void 0 ? defaultNormalizedCacheFactory() : _b,
      variables = _a.variables,
      dataIdFromObject = _a.dataIdFromObject,
      fragmentMatcherFunction = _a.fragmentMatcherFunction;
    return this.writeResultToStore({
      dataId: 'ROOT_QUERY',
      result: result,
      document: query,
      store: store,
      variables: variables,
      dataIdFromObject: dataIdFromObject,
      fragmentMatcherFunction: fragmentMatcherFunction
    });
  };
  StoreWriter.prototype.writeResultToStore = function (_a) {
    var dataId = _a.dataId,
      result = _a.result,
      document = _a.document,
      _b = _a.store,
      store = _b === void 0 ? defaultNormalizedCacheFactory() : _b,
      variables = _a.variables,
      dataIdFromObject = _a.dataIdFromObject,
      fragmentMatcherFunction = _a.fragmentMatcherFunction;
    var operationDefinition = getOperationDefinition(document);
    try {
      return this.writeSelectionSetToStore({
        result: result,
        dataId: dataId,
        selectionSet: operationDefinition.selectionSet,
        context: {
          store: store,
          processedData: {},
          variables: assign({}, getDefaultValues(operationDefinition), variables),
          dataIdFromObject: dataIdFromObject,
          fragmentMap: createFragmentMap(getFragmentDefinitions(document)),
          fragmentMatcherFunction: fragmentMatcherFunction
        }
      });
    } catch (e) {
      throw enhanceErrorWithDocument(e, document);
    }
  };
  StoreWriter.prototype.writeSelectionSetToStore = function (_a) {
    var _this = this;
    var result = _a.result,
      dataId = _a.dataId,
      selectionSet = _a.selectionSet,
      context = _a.context;
    var variables = context.variables,
      store = context.store,
      fragmentMap = context.fragmentMap;
    selectionSet.selections.forEach(function (selection) {
      var _a;
      if (!shouldInclude(selection, variables)) {
        return;
      }
      if (isField(selection)) {
        var resultFieldKey = resultKeyNameFromField(selection);
        var value = result[resultFieldKey];
        if (typeof value !== 'undefined') {
          _this.writeFieldToStore({
            dataId: dataId,
            value: value,
            field: selection,
            context: context
          });
        } else {
          var isDefered = false;
          var isClient = false;
          if (selection.directives && selection.directives.length) {
            isDefered = selection.directives.some(function (directive) {
              return directive.name && directive.name.value === 'defer';
            });
            isClient = selection.directives.some(function (directive) {
              return directive.name && directive.name.value === 'client';
            });
          }
          if (!isDefered && !isClient && context.fragmentMatcherFunction) {
            process.env.NODE_ENV === "production" || invariant.warn("Missing field " + resultFieldKey + " in " + JSON.stringify(result, null, 2).substring(0, 100));
          }
        }
      } else {
        var fragment = void 0;
        if (isInlineFragment(selection)) {
          fragment = selection;
        } else {
          fragment = (fragmentMap || {})[selection.name.value];
          process.env.NODE_ENV === "production" ? invariant(fragment, 3) : invariant(fragment, "No fragment named " + selection.name.value + ".");
        }
        var matches = true;
        if (context.fragmentMatcherFunction && fragment.typeCondition) {
          var id = dataId || 'self';
          var idValue = toIdValue({
            id: id,
            typename: undefined
          });
          var fakeContext = {
            store: new ObjectCache((_a = {}, _a[id] = result, _a)),
            cacheRedirects: {}
          };
          var match = context.fragmentMatcherFunction(idValue, fragment.typeCondition.name.value, fakeContext);
          if (!isProduction() && match === 'heuristic') {
            process.env.NODE_ENV === "production" || invariant.error('WARNING: heuristic fragment matching going on!');
          }
          matches = !!match;
        }
        if (matches) {
          _this.writeSelectionSetToStore({
            result: result,
            selectionSet: fragment.selectionSet,
            dataId: dataId,
            context: context
          });
        }
      }
    });
    return store;
  };
  StoreWriter.prototype.writeFieldToStore = function (_a) {
    var _b;
    var field = _a.field,
      value = _a.value,
      dataId = _a.dataId,
      context = _a.context;
    var variables = context.variables,
      dataIdFromObject = context.dataIdFromObject,
      store = context.store;
    var storeValue;
    var storeObject;
    var storeFieldName = storeKeyNameFromField(field, variables);
    if (!field.selectionSet || value === null) {
      storeValue = value != null && typeof value === 'object' ? {
        type: 'json',
        json: value
      } : value;
    } else if (Array.isArray(value)) {
      var generatedId = dataId + "." + storeFieldName;
      storeValue = this.processArrayValue(value, generatedId, field.selectionSet, context);
    } else {
      var valueDataId = dataId + "." + storeFieldName;
      var generated = true;
      if (!isGeneratedId(valueDataId)) {
        valueDataId = '$' + valueDataId;
      }
      if (dataIdFromObject) {
        var semanticId = dataIdFromObject(value);
        process.env.NODE_ENV === "production" ? invariant(!semanticId || !isGeneratedId(semanticId), 4) : invariant(!semanticId || !isGeneratedId(semanticId), 'IDs returned by dataIdFromObject cannot begin with the "$" character.');
        if (semanticId || typeof semanticId === 'number' && semanticId === 0) {
          valueDataId = semanticId;
          generated = false;
        }
      }
      if (!isDataProcessed(valueDataId, field, context.processedData)) {
        this.writeSelectionSetToStore({
          dataId: valueDataId,
          result: value,
          selectionSet: field.selectionSet,
          context: context
        });
      }
      var typename = value.__typename;
      storeValue = toIdValue({
        id: valueDataId,
        typename: typename
      }, generated);
      storeObject = store.get(dataId);
      var escapedId = storeObject && storeObject[storeFieldName];
      if (escapedId !== storeValue && isIdValue(escapedId)) {
        var hadTypename = escapedId.typename !== undefined;
        var hasTypename = typename !== undefined;
        var typenameChanged = hadTypename && hasTypename && escapedId.typename !== typename;
        process.env.NODE_ENV === "production" ? invariant(!generated || escapedId.generated || typenameChanged, 5) : invariant(!generated || escapedId.generated || typenameChanged, "Store error: the application attempted to write an object with no provided id but the store already contains an id of " + escapedId.id + " for this object. The selectionSet that was trying to be written is:\n" + JSON.stringify(field));
        process.env.NODE_ENV === "production" ? invariant(!hadTypename || hasTypename, 6) : invariant(!hadTypename || hasTypename, "Store error: the application attempted to write an object with no provided typename but the store already contains an object with typename of " + escapedId.typename + " for the object of id " + escapedId.id + ". The selectionSet that was trying to be written is:\n" + JSON.stringify(field));
        if (escapedId.generated) {
          if (typenameChanged) {
            if (!generated) {
              store.delete(escapedId.id);
            }
          } else {
            mergeWithGenerated(escapedId.id, storeValue.id, store);
          }
        }
      }
    }
    storeObject = store.get(dataId);
    if (!storeObject || !isEqual(storeValue, storeObject[storeFieldName])) {
      store.set(dataId, __assign(__assign({}, storeObject), (_b = {}, _b[storeFieldName] = storeValue, _b)));
    }
  };
  StoreWriter.prototype.processArrayValue = function (value, generatedId, selectionSet, context) {
    var _this = this;
    return value.map(function (item, index) {
      if (item === null) {
        return null;
      }
      var itemDataId = generatedId + "." + index;
      if (Array.isArray(item)) {
        return _this.processArrayValue(item, itemDataId, selectionSet, context);
      }
      var generated = true;
      if (context.dataIdFromObject) {
        var semanticId = context.dataIdFromObject(item);
        if (semanticId) {
          itemDataId = semanticId;
          generated = false;
        }
      }
      if (!isDataProcessed(itemDataId, selectionSet, context.processedData)) {
        _this.writeSelectionSetToStore({
          dataId: itemDataId,
          result: item,
          selectionSet: selectionSet,
          context: context
        });
      }
      return toIdValue({
        id: itemDataId,
        typename: item.__typename
      }, generated);
    });
  };
  return StoreWriter;
}();
function isGeneratedId(id) {
  return id[0] === '$';
}
function mergeWithGenerated(generatedKey, realKey, cache) {
  if (generatedKey === realKey) {
    return false;
  }
  var generated = cache.get(generatedKey);
  var real = cache.get(realKey);
  var madeChanges = false;
  Object.keys(generated).forEach(function (key) {
    var value = generated[key];
    var realValue = real[key];
    if (isIdValue(value) && isGeneratedId(value.id) && isIdValue(realValue) && !isEqual(value, realValue) && mergeWithGenerated(value.id, realValue.id, cache)) {
      madeChanges = true;
    }
  });
  cache.delete(generatedKey);
  var newRealValue = __assign(__assign({}, generated), real);
  if (isEqual(newRealValue, real)) {
    return madeChanges;
  }
  cache.set(realKey, newRealValue);
  return true;
}
function isDataProcessed(dataId, field, processedData) {
  if (!processedData) {
    return false;
  }
  if (processedData[dataId]) {
    if (processedData[dataId].indexOf(field) >= 0) {
      return true;
    } else {
      processedData[dataId].push(field);
    }
  } else {
    processedData[dataId] = [field];
  }
  return false;
}
var defaultConfig = {
  fragmentMatcher: new HeuristicFragmentMatcher(),
  dataIdFromObject: defaultDataIdFromObject,
  addTypename: true,
  resultCaching: true,
  freezeResults: false
};
function defaultDataIdFromObject(result) {
  if (result.__typename) {
    if (result.id !== undefined) {
      return result.__typename + ":" + result.id;
    }
    if (result._id !== undefined) {
      return result.__typename + ":" + result._id;
    }
  }
  return null;
}
var hasOwn$1 = Object.prototype.hasOwnProperty;
var OptimisticCacheLayer = function (_super) {
  __extends(OptimisticCacheLayer, _super);
  function OptimisticCacheLayer(optimisticId, parent, transaction) {
    var _this = _super.call(this, Object.create(null)) || this;
    _this.optimisticId = optimisticId;
    _this.parent = parent;
    _this.transaction = transaction;
    return _this;
  }
  OptimisticCacheLayer.prototype.toObject = function () {
    return __assign(__assign({}, this.parent.toObject()), this.data);
  };
  OptimisticCacheLayer.prototype.get = function (dataId) {
    return hasOwn$1.call(this.data, dataId) ? this.data[dataId] : this.parent.get(dataId);
  };
  return OptimisticCacheLayer;
}(ObjectCache);
var InMemoryCache = function (_super) {
  __extends(InMemoryCache, _super);
  function InMemoryCache(config) {
    if (config === void 0) {
      config = {};
    }
    var _this = _super.call(this) || this;
    _this.watches = new Set();
    _this.typenameDocumentCache = new Map();
    _this.cacheKeyRoot = new KeyTrie(canUseWeakMap);
    _this.silenceBroadcast = false;
    _this.config = __assign(__assign({}, defaultConfig), config);
    if (_this.config.customResolvers) {
      process.env.NODE_ENV === "production" || invariant.warn('customResolvers have been renamed to cacheRedirects. Please update your config as we will be deprecating customResolvers in the next major version.');
      _this.config.cacheRedirects = _this.config.customResolvers;
    }
    if (_this.config.cacheResolvers) {
      process.env.NODE_ENV === "production" || invariant.warn('cacheResolvers have been renamed to cacheRedirects. Please update your config as we will be deprecating cacheResolvers in the next major version.');
      _this.config.cacheRedirects = _this.config.cacheResolvers;
    }
    _this.addTypename = !!_this.config.addTypename;
    _this.data = _this.config.resultCaching ? new DepTrackingCache() : new ObjectCache();
    _this.optimisticData = _this.data;
    _this.storeWriter = new StoreWriter();
    _this.storeReader = new StoreReader({
      cacheKeyRoot: _this.cacheKeyRoot,
      freezeResults: config.freezeResults
    });
    var cache = _this;
    var maybeBroadcastWatch = cache.maybeBroadcastWatch;
    _this.maybeBroadcastWatch = wrap(function (c) {
      return maybeBroadcastWatch.call(_this, c);
    }, {
      makeCacheKey: function (c) {
        if (c.optimistic) {
          return;
        }
        if (c.previousResult) {
          return;
        }
        if (cache.data instanceof DepTrackingCache) {
          return cache.cacheKeyRoot.lookup(c.query, JSON.stringify(c.variables));
        }
      }
    });
    return _this;
  }
  InMemoryCache.prototype.restore = function (data) {
    if (data) this.data.replace(data);
    return this;
  };
  InMemoryCache.prototype.extract = function (optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }
    return (optimistic ? this.optimisticData : this.data).toObject();
  };
  InMemoryCache.prototype.read = function (options) {
    if (typeof options.rootId === 'string' && typeof this.data.get(options.rootId) === 'undefined') {
      return null;
    }
    var fragmentMatcher = this.config.fragmentMatcher;
    var fragmentMatcherFunction = fragmentMatcher && fragmentMatcher.match;
    return this.storeReader.readQueryFromStore({
      store: options.optimistic ? this.optimisticData : this.data,
      query: this.transformDocument(options.query),
      variables: options.variables,
      rootId: options.rootId,
      fragmentMatcherFunction: fragmentMatcherFunction,
      previousResult: options.previousResult,
      config: this.config
    }) || null;
  };
  InMemoryCache.prototype.write = function (write) {
    var fragmentMatcher = this.config.fragmentMatcher;
    var fragmentMatcherFunction = fragmentMatcher && fragmentMatcher.match;
    this.storeWriter.writeResultToStore({
      dataId: write.dataId,
      result: write.result,
      variables: write.variables,
      document: this.transformDocument(write.query),
      store: this.data,
      dataIdFromObject: this.config.dataIdFromObject,
      fragmentMatcherFunction: fragmentMatcherFunction
    });
    this.broadcastWatches();
  };
  InMemoryCache.prototype.diff = function (query) {
    var fragmentMatcher = this.config.fragmentMatcher;
    var fragmentMatcherFunction = fragmentMatcher && fragmentMatcher.match;
    return this.storeReader.diffQueryAgainstStore({
      store: query.optimistic ? this.optimisticData : this.data,
      query: this.transformDocument(query.query),
      variables: query.variables,
      returnPartialData: query.returnPartialData,
      previousResult: query.previousResult,
      fragmentMatcherFunction: fragmentMatcherFunction,
      config: this.config
    });
  };
  InMemoryCache.prototype.watch = function (watch) {
    var _this = this;
    this.watches.add(watch);
    return function () {
      _this.watches.delete(watch);
    };
  };
  InMemoryCache.prototype.evict = function (query) {
    throw process.env.NODE_ENV === "production" ? new InvariantError(7) : new InvariantError("eviction is not implemented on InMemory Cache");
  };
  InMemoryCache.prototype.reset = function () {
    this.data.clear();
    this.broadcastWatches();
    return Promise.resolve();
  };
  InMemoryCache.prototype.removeOptimistic = function (idToRemove) {
    var toReapply = [];
    var removedCount = 0;
    var layer = this.optimisticData;
    while (layer instanceof OptimisticCacheLayer) {
      if (layer.optimisticId === idToRemove) {
        ++removedCount;
      } else {
        toReapply.push(layer);
      }
      layer = layer.parent;
    }
    if (removedCount > 0) {
      this.optimisticData = layer;
      while (toReapply.length > 0) {
        var layer_1 = toReapply.pop();
        this.performTransaction(layer_1.transaction, layer_1.optimisticId);
      }
      this.broadcastWatches();
    }
  };
  InMemoryCache.prototype.performTransaction = function (transaction, optimisticId) {
    var _a = this,
      data = _a.data,
      silenceBroadcast = _a.silenceBroadcast;
    this.silenceBroadcast = true;
    if (typeof optimisticId === 'string') {
      this.data = this.optimisticData = new OptimisticCacheLayer(optimisticId, this.optimisticData, transaction);
    }
    try {
      transaction(this);
    } finally {
      this.silenceBroadcast = silenceBroadcast;
      this.data = data;
    }
    this.broadcastWatches();
  };
  InMemoryCache.prototype.recordOptimisticTransaction = function (transaction, id) {
    return this.performTransaction(transaction, id);
  };
  InMemoryCache.prototype.transformDocument = function (document) {
    if (this.addTypename) {
      var result = this.typenameDocumentCache.get(document);
      if (!result) {
        result = addTypenameToDocument(document);
        this.typenameDocumentCache.set(document, result);
        this.typenameDocumentCache.set(result, result);
      }
      return result;
    }
    return document;
  };
  InMemoryCache.prototype.broadcastWatches = function () {
    var _this = this;
    if (!this.silenceBroadcast) {
      this.watches.forEach(function (c) {
        return _this.maybeBroadcastWatch(c);
      });
    }
  };
  InMemoryCache.prototype.maybeBroadcastWatch = function (c) {
    c.callback(this.diff({
      query: c.query,
      variables: c.variables,
      previousResult: c.previousResult && c.previousResult(),
      optimistic: c.optimistic
    }));
  };
  return InMemoryCache;
}(ApolloCache);
export { HeuristicFragmentMatcher, InMemoryCache, IntrospectionFragmentMatcher, ObjectCache, StoreReader, StoreWriter, WriteError, assertIdValue, defaultDataIdFromObject, defaultNormalizedCacheFactory$1 as defaultNormalizedCacheFactory, enhanceErrorWithDocument };
