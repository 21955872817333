import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  mergeApplicationConfig,
  TransferState,
} from '@angular/core';
import { RenderDocumentProvider } from './services/render-document-provider';
import { customLogger } from './app/custom-logger-provider';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { appConfig } from './app/app.config';
import { RenderDocumentFrontendImpl } from './services/render-document-frontend-impl';
import { ApplicationValues } from '../models';
import {
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './app/translate-browser.loader';
import { STRAPI_SYSTEM_CONFIG } from '@himingle/ui-strapi-wrapper';

export const createTranslateLoader = (
  http: HttpClient,
  transferState: TransferState,
  renderDocumentProvider: RenderDocumentProvider,
) => {
  if (renderDocumentProvider.isBrowser()) {
    return translateBrowserLoaderFactory(http, transferState);
  } else {
    return new TranslateFakeLoader();
  }
};

export const renderProvider = {
  provide: RenderDocumentProvider,
  useClass: RenderDocumentFrontendImpl,
};
//
export const initializerWithData = (data: ApplicationValues) => {
  return (renderDocumentProvider: RenderDocumentProvider) => {
    return async () => {
      if (renderDocumentProvider.isBrowser()) {
        return renderDocumentProvider.init(data).then((res) => res.initialized);
      } else {
        throw 'incorrect configuration';
      }
    };
  };
};

const frontendConfig: ApplicationConfig = {
  providers: [customLogger, renderProvider, provideHttpClient(withFetch())],
};

export const config = mergeApplicationConfig(appConfig, frontendConfig);

fetch('/assets/env/env.dc2be8c0.json')
  .then((response) => response.json())
  .then((data: ApplicationValues) => {
    return bootstrapApplication(
      AppComponent,
      mergeApplicationConfig(appConfig, {
        providers: [
          renderProvider,
          customLogger,
          {
            provide: STRAPI_SYSTEM_CONFIG,
            useValue: {
              graphql: {
                url: data.graphQLUrl || '/s/graphql',
              },
            },
          },
          {
            provide: APP_INITIALIZER,
            useFactory: initializerWithData(data),
            multi: true,
            deps: [RenderDocumentProvider],
          },
          importProvidersFrom(
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, TransferState, RenderDocumentProvider],
              },
            }),
          ),
        ],
      }),
    ).catch((err) => console.error(err));
  })
  .catch((err) => console.error('Cannot load env json', err));
