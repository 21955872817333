import * as i0 from '@angular/core';
import { Directive, Input, PLATFORM_ID, Inject, EventEmitter, Output, HostListener, Pipe, NgModule } from '@angular/core';
import { isPlatformServer, CommonModule } from '@angular/common';
import { marked } from 'marked';
import * as i1 from '@angular/platform-browser';
import { isObservable, firstValueFrom, of, tap, map } from 'rxjs';
import * as i1$1 from '@himingle/shared';
class LetDirective {
  constructor(viewContainer, templateRef) {
    this.context = {
      ngLet: null
    };
    viewContainer.createEmbeddedView(templateRef, this.context);
  }
  set ngLet(value) {
    this.context.ngLet = value;
  }
  static {
    this.ɵfac = function LetDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LetDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LetDirective,
      selectors: [["", "ngLet", ""]],
      inputs: {
        ngLet: "ngLet"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LetDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[ngLet]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }, {
    type: i0.TemplateRef
  }], {
    ngLet: [{
      type: Input
    }]
  });
})();
class AppServerRenderDirective {
  constructor(platformId, templateRef, viewContainer) {
    this.platformId = platformId;
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
  }
  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
  static {
    this.ɵfac = function AppServerRenderDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppServerRenderDirective)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AppServerRenderDirective,
      selectors: [["", "appServerRender", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppServerRenderDirective, [{
    type: Directive,
    args: [{
      /* eslint-disable @angular-eslint/directive-selector */
      selector: '[appServerRender]'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], null);
})();
class AppClickOutsideDirective {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.clickOutside = new EventEmitter();
  }
  onClick(targetElement) {
    const isClickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!isClickedInside) {
      this.clickOutside.emit(targetElement);
    }
  }
  static {
    this.ɵfac = function AppClickOutsideDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppClickOutsideDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AppClickOutsideDirective,
      selectors: [["", "appClickOutside", ""]],
      hostBindings: function AppClickOutsideDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function AppClickOutsideDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      outputs: {
        clickOutside: "clickOutside"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppClickOutsideDirective, [{
    type: Directive,
    args: [{
      /* eslint-disable @angular-eslint/directive-selector */
      selector: '[appClickOutside]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    clickOutside: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }]
  });
})();
class AppEscapeKeyDirective {
  constructor() {
    this.escapeKey = new EventEmitter();
  }
  onEscapeKey() {
    this.escapeKey.emit();
  }
  static {
    this.ɵfac = function AppEscapeKeyDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppEscapeKeyDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AppEscapeKeyDirective,
      selectors: [["", "appKeydownEscapeWindow", ""]],
      hostBindings: function AppEscapeKeyDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.escape", function AppEscapeKeyDirective_keydown_escape_HostBindingHandler() {
            return ctx.onEscapeKey();
          }, false, i0.ɵɵresolveDocument);
        }
      },
      outputs: {
        escapeKey: "escapeKey"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppEscapeKeyDirective, [{
    type: Directive,
    args: [{
      /* eslint-disable @angular-eslint/directive-selector */
      selector: '[appKeydownEscapeWindow]'
    }]
  }], null, {
    escapeKey: [{
      type: Output
    }],
    onEscapeKey: [{
      type: HostListener,
      args: ['document:keydown.escape']
    }]
  });
})();
class AppServerNoRenderDirective {
  constructor(platformId, templateRef, viewContainer) {
    this.platformId = platformId;
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
  }
  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
  static {
    this.ɵfac = function AppServerNoRenderDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AppServerNoRenderDirective)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AppServerNoRenderDirective,
      selectors: [["", "appServerNoRender", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppServerNoRenderDirective, [{
    type: Directive,
    args: [{
      /* eslint-disable @angular-eslint/directive-selector */
      selector: '[appServerNoRender]'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], null);
})();
class MarkdownPipe {
  transform(value, args) {
    if (value && value.length > 0) {
      return marked(value);
    }
    return value;
  }
  static {
    this.ɵfac = function MarkdownPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkdownPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "markdown",
      type: MarkdownPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkdownPipe, [{
    type: Pipe,
    args: [{
      name: 'markdown'
    }]
  }], null, null);
})();
class SafeHtmlPipe {
  constructor(sanitized) {
    this.sanitized = sanitized;
  }
  transform(value) {
    return value ? this.sanitized.bypassSecurityTrustHtml(value) : '';
  }
  static {
    this.ɵfac = function SafeHtmlPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SafeHtmlPipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "safeHtml",
      type: SafeHtmlPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SafeHtmlPipe, [{
    type: Pipe,
    args: [{
      name: 'safeHtml'
    }]
  }], () => [{
    type: i1.DomSanitizer
  }], null);
})();
class FileSizePipe {
  transform(bytes) {
    if (isNaN(bytes) || bytes === 0) return '0 Bytes';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
  }
  static {
    this.ɵfac = function FileSizePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FileSizePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "fileSize",
      type: FileSizePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSizePipe, [{
    type: Pipe,
    args: [{
      name: 'fileSize'
    }]
  }], null, null);
})();
class SharedModule {
  static {
    this.ɵfac = function SharedModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      declarations: [AppServerNoRenderDirective, AppServerRenderDirective, AppClickOutsideDirective, AppEscapeKeyDirective, LetDirective, SafeHtmlPipe, FileSizePipe, MarkdownPipe],
      imports: [CommonModule],
      exports: [AppServerNoRenderDirective, AppServerRenderDirective, AppClickOutsideDirective, AppEscapeKeyDirective, LetDirective, SafeHtmlPipe, FileSizePipe, MarkdownPipe]
    }]
  }], null, null);
})();
function normalizeRawError(error, logger) {
  if (error instanceof Error) {
    return JSON.stringify({
      _error: error,
      name: error.name,
      message: error.message,
      stack: error.stack
    });
  }
  if (error instanceof Object) {
    return error.toString();
  }
  try {
    return JSON.stringify(error);
  } catch (e) {
    logger.error('Failed to stringify error', e);
  }
}
class CoreModule {
  constructor(transferState, logger) {
    this.transferState = transferState;
    this.logger = logger;
    this.waitFor = async prom => {
      if (isObservable(prom)) {
        prom = firstValueFrom(prom);
      }
      const macroTask = Zone.current.scheduleMacroTask(`WAITFOR-${Math.random()}`, () => {
        return;
      }, {}, () => {
        return;
      });
      return prom.then(p => {
        macroTask.invoke();
        return p;
      });
    };
  }
  wrapObservable(query, isServer, cacheDataKey, clearCacheValue) {
    if (cacheDataKey) {
      const data = this.transferState.get(cacheDataKey, undefined);
      if (clearCacheValue) {
        this.transferState.remove(cacheDataKey);
      }
      if (data) {
        return of(data);
      }
    }
    if (isServer) {
      return query.pipe(tap(x => {
        if (cacheDataKey && x?.data) {
          this.transferState.set(cacheDataKey, x?.data);
        }
      }), map(x => x?.data));
    } else {
      return query.pipe(map(x => x?.data));
    }
  }
  async wrapRequest(promise, isServer, cacheDataKey, clearCacheValue) {
    if (cacheDataKey) {
      const data = this.transferState.get(cacheDataKey, undefined);
      if (clearCacheValue) {
        this.transferState.remove(cacheDataKey);
      }
      if (data) {
        return {
          data
        };
      }
    }
    if (isServer) {
      const v = await this.waitFor(promise);
      if (v.data && cacheDataKey) {
        this.transferState.set(cacheDataKey, v.data);
      }
      if (v.error) {
        this.logger.error('request exception', {
          error: normalizeRawError(v.error, this.logger)
        });
      }
      return v;
    } else {
      return await promise;
    }
  }
  static {
    this.ɵfac = function CoreModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CoreModule)(i0.ɵɵinject(i0.TransferState), i0.ɵɵinject(i1$1.CustomLogger));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule]
    }]
  }], () => [{
    type: i0.TransferState
  }, {
    type: i1$1.CustomLogger
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AppClickOutsideDirective, AppEscapeKeyDirective, AppServerNoRenderDirective, AppServerRenderDirective, CoreModule, FileSizePipe, LetDirective, MarkdownPipe, SafeHtmlPipe, SharedModule, normalizeRawError };
