import { RenderDocumentProvider } from './render-document-provider';
import {
  AuthChangeEvent,
  AuthSession,
  createClient,
  Session,
  SupabaseClient,
} from '@supabase/supabase-js';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { Role } from '../app/models';
import { ApplicationValues } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class RenderDocumentFrontendImpl extends RenderDocumentProvider {
  supabase: SupabaseClient | undefined;

  roles$ = new Subject<Role[]>();
  roles: Role[] = [];
  private _session$: Subject<AuthSession | null> =
    new Subject<AuthSession | null>();
  private predefined = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    private injector: Injector,
  ) {
    const platformId = injector.get(PLATFORM_ID);
    super(platformId);
  }

  whoIam(): string {
    return 'RenderDocumentFrontendImpl';
  }

  override async init(applicationValues: ApplicationValues) {
    this._applicationValues = applicationValues;
    try {
      this.supabase = createClient(
        applicationValues.supabaseUrl,
        applicationValues.supabaseKey,
      );
      this.supabase.auth.onAuthStateChange(this.onAuthStateChange);
      return {
        initialized: true,
        hasSession: this.supabase?.auth.getSession() !== null,
      };
    } catch {
      return { initialized: false, hasSession: false };
    }
  }
  override getSupabase(): SupabaseClient {
    if (this.supabase) {
      return this.supabase;
    }
    throw new Error('no supabase client available');
  }

  override getSession(): Promise<AuthSession | null> {
    return this.supabase
      ? this.supabase!.auth.getSession().then(({ data }) => {
          this._session = data.session;
          setTimeout(() => {
            if (this._session) {
              this._session$.next(this._session);
            }
          });
          return data.session;
        })
      : Promise.resolve(null);
  }

  override eraseSession() {
    this._session = null;
    setTimeout(() => {
      this._session$.next(null);
    });
  }
  override hasSession() {
    return this.getSession().then((session) => session !== null);
  }

  private onAuthStateChange = (
    event: AuthChangeEvent,
    session: Session | null,
  ): void | Promise<void> => {
    if (session && session.user) {
      if (!this.predefined) {
        this.predefined = true;

        // setTimeout(async () => {
        //   try {
        //     await this.supabase?.auth.startAutoRefresh();
        //
        //     const respStatusesPromise = await this.supabase
        //       ?.from('statuses')
        //       .select('*');
        //     const respRolesPromise = await this.supabase
        //       ?.from('roles')
        //       .select('*');
        //     if (respStatusesPromise) {
        //       const { data } = respStatusesPromise;
        //       const statuses: StatusDB[] =
        //         data?.map((x) => {
        //           return {
        //             status_uid: x.status_uid,
        //             code: x.code,
        //             name: x.name,
        //           };
        //         }) || [];
        //       this.statuses = statuses;
        //       setTimeout(() => this.statuses$.next(statuses));
        //     }
        //
        //     if (respRolesPromise) {
        //       const { data } = respRolesPromise;
        //       const roles: Role[] =
        //         data?.map((x) => {
        //           return { role_uid: x.role_uid, code: x.code, name: x.name };
        //         }) || [];
        //       this.roles = roles;
        //       setTimeout(() => this.roles$.next(roles));
        //     }
        //   } catch (err) {
        //     console.error(err);
        //   }
        // }, 0);
      }
    }
    return Promise.resolve();
  };
}
