import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: 'footer[web-footer]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './web-footer.component.html',
})
export class WebFooterComponent {}
