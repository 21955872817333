import {
  APP_ID,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideRouterStore,
  routerReducer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@himingle/ui-common';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WEBSITE_DEFAULT_VALUES_CONFIG } from './website-default';
import { websiteDefaultValues } from './website-default-values';

export const appConfig: ApplicationConfig = {
  providers: [
    provideStore({
      router: routerReducer,
    }),
    { provide: APP_ID, useValue: 'webBackend' },
    {
      provide: WEBSITE_DEFAULT_VALUES_CONFIG,
      useValue: websiteDefaultValues,
    },
    provideRouterStore(),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideRouter(appRoutes),
    importProvidersFrom(
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      CoreModule,
      EffectsModule.forRoot(),
      StoreModule.forRoot(
        {
          router: routerReducer,
        },
        {
          runtimeChecks: {
            strictActionImmutability: false,
            strictActionSerializability: false,
            strictActionTypeUniqueness: false, // isDevMode(),
            strictActionWithinNgZone: isDevMode(),
            strictStateImmutability: isDevMode(),
            strictStateSerializability: false,
          },
        },
      ),
      StoreRouterConnectingModule.forRoot({}),
      StoreDevtoolsModule.instrument(),
    ),
  ],
};
