import { Route } from '@angular/router';
import { WebLayoutComponent } from './web-layout/web-layout.component';
import { environment } from '../environments/environment';

export const appRoutes: Route[] = [
  !environment.config.stub
    ? {
        path: '',
        component: WebLayoutComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/content/web-content/web-content.component').then(
                (m) => m.WebContentComponent,
              ),
          },
        ],
      }
    : null,
  environment.config.stub
    ? {
        path: '**',
        component: WebLayoutComponent,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./pages/content/stub/stub-content.component').then(
                (m) => m.StubComponent,
              ),
          },
        ],
      }
    : null,
  !environment.config.stub
    ? {
        path: 'content',
        component: WebLayoutComponent,
        children: [
          {
            path: '**',
            loadComponent: () =>
              import('./pages/content/web-content/web-content.component').then(
                (m) => m.WebContentComponent,
              ),
          },
        ],
      }
    : null,
  // {
  //   path: 'errors',
  //   loadChildren: () =>
  //     import('./pages/errors/errors.module').then((m) => m.ErrorsModule),
  // },
  // { path: '**', redirectTo: '/errors/not-found' },
].filter((x) => !!x);
