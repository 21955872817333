import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { WebHeaderComponent } from './web-header.component';
import { WebFooterComponent } from './web-footer.component';

@Component({
  selector: 'app-web-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, WebHeaderComponent, WebFooterComponent],
  templateUrl: './web-layout.component.html',
})
export class WebLayoutComponent {}
