import * as i0 from '@angular/core';
import { InjectionToken, Injector, NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-angular/http';
class StrapiWrapperProvider {}
const STRAPI_SYSTEM_CONFIG = new InjectionToken('StrapiSystemConfig');
function createApollo(httpLink, provider, injector) {
  const config = injector.get(STRAPI_SYSTEM_CONFIG);
  const link = httpLink.create({
    uri: config.graphql.url
  });
  const introspectionQueryResultData = provider.getIntrospectionResultData();
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });
  const cache = new InMemoryCache({
    fragmentMatcher
  });
  return {
    link,
    cache,
    ssrForceFetchDelay: 100
  };
}
class GraphQLModule {
  static {
    this.ɵfac = function GraphQLModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GraphQLModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: GraphQLModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: APOLLO_OPTIONS,
        useFactory: createApollo,
        deps: [HttpLink, StrapiWrapperProvider, Injector]
      }],
      imports: [ApolloModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GraphQLModule, [{
    type: NgModule,
    args: [{
      exports: [ApolloModule],
      providers: [{
        provide: APOLLO_OPTIONS,
        useFactory: createApollo,
        deps: [HttpLink, StrapiWrapperProvider, Injector]
      }]
    }]
  }], null, null);
})();
class StrapiWrapperModule {
  static {
    this.ɵfac = function StrapiWrapperModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StrapiWrapperModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StrapiWrapperModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, GraphQLModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StrapiWrapperModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, GraphQLModule]
    }]
  }], null, null);
})();
const checkTextInlineNode = p =>
// eslint-disable-next-line no-prototype-builtins
p.hasOwnProperty('bold') ||
// eslint-disable-next-line no-prototype-builtins
p.hasOwnProperty('italic') ||
// eslint-disable-next-line no-prototype-builtins
p.hasOwnProperty('underline') ||
// eslint-disable-next-line no-prototype-builtins
p.hasOwnProperty('strikethrough') ||
// eslint-disable-next-line no-prototype-builtins
p.hasOwnProperty('code');
class HtmlRendererService {
  constructor(rendererFactory) {
    this.rendererFactory = rendererFactory;
    this.voidTypes = ['image'];
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }
  render(nodes, options) {
    const root = this.renderer2.createElement('div');
    nodes.forEach((node, index) => {
      this.apply(root, node, index, options);
    });
    return root;
  }
  apply(parent, node, index, options) {
    const {
      type,
      children
    } = node;
    // Handle void types separately as they should not render children
    const elems = this.createElement(node, index, options);
    if (elems) {
      this.renderer2.appendChild(parent, elems.element);
    }
    if (this.voidTypes.includes(type)) {
      return;
    }
    if (elems) {
      children?.forEach((childNode, childIndex) => {
        this.applyChild(elems.parent, childNode, childIndex, options);
      });
    }
  }
  createElement(node, index, options) {
    if (node.type === 'code') {
      const element = this.renderer2.createElement(`pre`);
      const parent = this.renderer2.createElement(`code`);
      this.renderer2.appendChild(element, parent);
      return {
        parent,
        element
      };
    } else if (node.type === 'link') {
      const element = this.createLink(node, index, options);
      return {
        parent: element,
        element
      };
    } else if (node.type === 'list') {
      const element = this.createList(node, index, options);
      return {
        parent: element,
        element
      };
    } else if (node.type === 'image') {
      const elementImage = this.renderer2.createElement(`img`);
      elementImage.alt = node.image.alternativeText || '';
      elementImage.src = node.image.url;
      const element = elementImage;
      if (options?.applyImageBlockNode) {
        const elems = options.applyImageBlockNode(elementImage, node.type, node);
        return {
          parent: elems.parent,
          element: elems.element
        };
      }
      return {
        parent: element,
        element
      };
      // TODO
    } else if (node.type === 'heading') {
      const element = this.renderer2.createElement(`h${node.level}`);
      return {
        parent: element,
        element
      };
    } else if (node.type === 'quote') {
      const element = this.renderer2.createElement(`blockquote`);
      return {
        parent: element,
        element
      };
    } else if (node.type === 'list-item') {
      const element = this.createListItem(node, index, options);
      return {
        parent: element,
        element
      };
    } else if (node.type === 'paragraph') {
      const element = this.renderer2.createElement(`p`);
      return {
        parent: element,
        element
      };
    }
    return undefined;
  }
  applyChild(parent, node, index, options) {
    const {
      type
    } = node;
    if (type === 'text') {
      const elems = this.createAlternativeElement(node, index, options);
      if (elems) {
        this.renderer2.appendChild(parent, elems.element);
      }
    } else {
      const {
        children
      } = node;
      const elems = this.createElement(node, index, options);
      if (elems) {
        this.renderer2.appendChild(parent, elems.element);
        children?.forEach((childNode, childIndex) => {
          this.applyChild(elems.parent, childNode, childIndex, options);
        });
      }
    }
  }
  createAlternativeElement(node, index, options) {
    if (node.type === 'text') {
      if (!node.text) {
        return undefined;
      }
      if (checkTextInlineNode(node)) {
        const textNode = node;
        if (textNode.code) {
          const element = this.renderer2.createElement(`pre`);
          const parent = this.renderer2.createElement(`code`);
          parent.innerText = textNode.text;
          this.renderer2.appendChild(element, parent);
          return {
            element,
            parent
          };
        }
        const element = this.renderer2.createElement('span');
        element.innerText = node.text;
        if (options?.applyTextInlineNode) {
          const elems = options?.applyTextInlineNode(element, node.type, textNode, {
            bold: textNode.bold,
            italic: textNode.italic,
            underline: textNode.underline,
            strikethrough: textNode.strikethrough
          });
          return {
            element: elems.element,
            parent: elems.parent
          };
        }
        return {
          element,
          parent: element
        };
      } else {
        const element = this.renderer2.createElement('span');
        element.innerText = node.text;
        return {
          element,
          parent: element
        };
      }
    } else if (node.type === 'link') {
      const element = this.createLink(node, index, options);
      return {
        element,
        parent: element
      };
    } else if (node.type === 'list') {
      const element = this.createList(node, index, options);
      return {
        element,
        parent: element
      };
    } else if (node.type === 'list-item') {
      const element = this.createListItem(node, index, options);
      return {
        element,
        parent: element
      };
    }
    return undefined;
  }
  createLink(node, index, options) {
    const elem = this.renderer2.createElement('a');
    const url = node.url;
    const urlUpdate = url.replace('!blank', '');
    if (urlUpdate !== url) {
      elem.target = '_blank';
    }
    elem.href = urlUpdate;
    return elem;
  }
  createList(node, index, options) {
    if (node.format === 'ordered') {
      const elem = this.renderer2.createElement('ol');
      return elem;
    } else if (node.format === 'unordered') {
      const elem = this.renderer2.createElement('ul');
      return elem;
    }
    return undefined;
  }
  createListItem(node, index, options) {
    const elem = this.renderer2.createElement('li');
    return elem;
  }
  static {
    this.ɵfac = function HtmlRendererService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HtmlRendererService)(i0.ɵɵinject(i0.RendererFactory2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HtmlRendererService,
      factory: HtmlRendererService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HtmlRendererService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.RendererFactory2
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { HtmlRendererService, STRAPI_SYSTEM_CONFIG, StrapiWrapperModule, StrapiWrapperProvider, checkTextInlineNode };
