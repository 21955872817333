import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { RenderDocumentProvider } from '../../services/render-document-provider';

@Component({
  /* eslint-disable @angular-eslint/component-selector */
  selector: 'header[web-header]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './web-header.component.html',
})
export class WebHeaderComponent implements OnInit, OnDestroy {
  noSessionAvailable = true;
  open = false;
  expanded = false;
  appUrl = ''; //environment.config.appUrl;
  private destroyed$ = new Subject();

  constructor(private renderDocumentProvider: RenderDocumentProvider) {}

  // get sessionAvailable() {
  //   return !this.noSessionAvailable;
  // }

  ngOnInit(): void {
    if (this.renderDocumentProvider.isBrowser()) {
      this.renderDocumentProvider.hasSession().then((x) => {
        this.noSessionAvailable = !x;
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  toggleDropdown(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }
    this.open = !this.open;
  }

  toggleMenu(event: Event): void {
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  closeMenu(event: any): void {
    if (!event.target['closest']('#mobile-nav')) {
      this.expanded = false;
    }
  }
}
